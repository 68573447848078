* {
  font-family: "Noto Sans", sans-serif;
}
.home {
  background-color: #ffc700;
  height: 100vh;
  width: 100vw;
  .center-col {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .info-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 500px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    gap: 20px;

    .image-box-wrapper {
      width: 200px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #686868;
      }
    }
    .content {
      text-align: center;
      h1 {
        color: #000;
        font-size: 54px;
        font-weight: 100;
        letter-spacing: 0.54px;
        font-size: 54px;
      }
      .tags {
        display: flex;
        justify-content: space-between;
        p {
          color: #201b1b;
          font-family: Noto Sans;
          font-size: 19px;
          font-weight: 200;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .home {
    .info-box-wrapper {
      .image-box-wrapper {
        width: 130px;
      }
      .content {
        h1 {
          font-size: 30px;
        }
        .tags {
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
}
